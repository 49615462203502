import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import ArrowRight from 'images/arrow-right.svg';
import { Divider } from 'utils';
import Link from 'layouts/Main/components/Footer/FooterLink';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { GridSizedContainer } from '../AdvisorsSection/AdvisorsSection';

const ContentBox = styled(Container)``;
const Title = styled(Typography)`` as StyledPropsFix;
const Text = styled(Typography)`` as StyledPropsFix;
const ProjectLink = styled(Link)``;

const CareerSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .careerSection-contentBox {
    display: flex;
    flex-direction: column;
    padding: 0 1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
    }
  }
  .careerSection-text {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.secondary};
    text-align: left;
    font-size: 1.5em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.1em;
      padding-top: 1em;
    }
  }
  .careerSection-Title {
    font-family: Poppins SemiBold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.secondary};
    padding-bottom: 0.5em;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 2.1em;
      line-height: 50px;
    }
  }
`;

export const RightArrow = styled('span')`
  display: inline-block;
  vertical-align: middle;
  width: 19px;
  height: 13px;

  background-image: url(${ArrowRight});
`;

const CareersSection = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <CareerSectionStyles>
      <Box
        sx={{ backgroundColor: '#3F2A62' }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
          <Divider height="4.3em" />
          <ContentBox className="careerSection-contentBox">
            <Title component="h1" variant="h4" className="careerSection-Title">
              Careers
            </Title>
            <Text component="p" className="careerSection-text">
              Our vision is to build rental properties worldwide.
            </Text>
            <Text component="p" className="careerSection-text">
              Join us and let&apos;s create impact on a global scale.
            </Text>
            <ProjectLink href="/careers" sx={{ paddingTop: '1em' }}>
              View open positions <RightArrow />
            </ProjectLink>
          </ContentBox>
          <Divider height="4.3em" />
        </GridSizedContainer>
      </Box>
    </CareerSectionStyles>
  );
};

export default CareersSection;
