import React from 'react';
import FirstImage from 'images/ourTeam/01.svg';
import SecondImage from 'images/ourTeam/02.svg';
import ThirdImage from 'images/ourTeam/03.svg';
// import ForthImage from 'images/ourTeam/04.svg';
import FifthImage from 'images/ourTeam/05.svg';
import SixthImage from 'images/ourTeam/06.svg';
import SeventhImage from 'images/ourTeam/07.svg';
import EighthImage from 'images/ourTeam/08.svg';
import NinethImage from 'images/ourTeam/09.svg';
import TenthImage from 'images/ourTeam/10.svg';
import EleventhImage from 'images/ourTeam/11.svg';
import TwelvethImage from 'images/ourTeam/12.svg';
import PersonCards from '../PersonCards';
import { Box } from '@mui/material';
import { Anchor, Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { GridSizedContainer } from '../AdvisorsSection/AdvisorsSection';

const content = [
  {
    id: 1,
    name: 'Kristaps Cuders',
    src: FirstImage,
    linkedinUrl: 'https://www.linkedin.com/in/kristapscuders/',
    description: 'Co-founder & CEO',
  },
  {
    id: 2,
    name: 'Santa Krieva',
    src: SecondImage,
    linkedinUrl: 'https://www.linkedin.com/in/santa-krieva/',
    description:
      'Co-founder & Design Director, Architecture and Sustainability',
  },
  {
    id: 3,
    name: 'Martins Spelmanis',
    src: ThirdImage,
    linkedinUrl: 'https://www.linkedin.com/in/martins-spelmanis/',
    description: 'Co-founder & Head of Construction and Execution',
  },
  {
    id: 4,
    name: 'Ieva Ernestovska',
    src: FifthImage,
    linkedinUrl: 'https://www.linkedin.com/in/ievaernestovska/',
    description: 'Head of Marketing / Partner',
  },
  {
    id: 5,
    name: 'Agris Jurjans',
    src: SixthImage,
    linkedinUrl: 'https://www.linkedin.com/in/agris-jurjans/',
    description: 'Software Developer / Partner',
  },
  {
    id: 6,
    name: 'Liga Luize Adamsone',
    src: SeventhImage,
    linkedinUrl: 'https://www.linkedin.com/in/ligaluize/',
    description: 'UX/UI Designer / Partner',
  },
  {
    id: 7,
    name: 'Volodymyr Havrylyuk-Yensen',
    src: EighthImage,
    linkedinUrl: 'https://www.linkedin.com/in/volodymyr-h-y/',
    description: 'Legal Advisor / Partner',
  },
  {
    id: 8,
    name: 'Adrianna Białończyk',
    src: NinethImage,
    linkedinUrl:
      'https://www.linkedin.com/in/adrianna-bia%C5%82o%C5%84czyk-016aab145/',
    description: 'Architect / Partner',
  },
  {
    id: 9,
    name: 'Lauris Borodovskis',
    src: TenthImage,
    linkedinUrl: 'https://www.linkedin.com/in/lauris-borodovskis-640b37157/',
    description: 'Digital Project Manager / Partner',
  },
  {
    id: 10,
    name: 'Adam Blazsek',
    src: EleventhImage,
    linkedinUrl: 'https://www.linkedin.com/in/adamblazsek/',
    description: 'Tokenization Expert / Partner',
  },  
  {
    id: 10,
    name: 'Kristaps Grinbergs',
    src: TwelvethImage,
    linkedinUrl: 'https://www.linkedin.com/in/kristapsgrinbergs/',
    description: 'Web3 Blockchain fullstack developer/Partner',
  },
];

const OurTeamSection = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Divider height="10.3em" />
        <Anchor id="team" />
        <PersonCards content={content} title="Our executive team" />
        <Divider height="10.3em" />
      </GridSizedContainer>
    </Box>
  );
};

export default OurTeamSection;
