import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, Theme } from '@mui/system';
import { StyledPropsFix } from 'types';
import { Divider } from 'utils';
import { Paper } from '@mui/material';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const Title = styled(Typography)`` as StyledPropsFix;

const ContentBox = styled(Container)``;

const PersonCardsStyles = styled(Box)<{
  themeText?: string;
  sectionBackground?: string;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ sectionBackground }) => sectionBackground};

  .logoCards-contentBox {
    display: flex;
    flex-direction: column;
  }

  .logoCards-cardBox {
    display: flex;
    flex-direction: row;
    gap: 0px 59px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .logoCards-Title {
    font-family: Roboto Bold;
    font-size: 2.1em;
    color: ${({ theme, themeText = 'dark' }) => theme.palette.text[themeText]};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.6em;
      top: -60px;
    }
  }
`;

const CardBox = styled(Box)`` as StyledPropsFix;

const CardBoxContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ${({ theme }) => theme.breakpoints.down('md')} {
    transform: scale(0.9);
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    justify-content: center;
  }
` as StyledPropsFix;

const Card = styled(Paper)`
  align-items: center;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  height: 154px;
  width: 154px;
  justify-content: center;
` as StyledPropsFix;

const Image = styled('img')`
  height: 100%;
  width: 100%;
  margin-bottom: 1em;
`;

type LogoCardContent = {
  id: number;
  src: string;
};

const logoCards = ({
  content,
  title,
  titleColor,
  sectionBackground,
}: {
  content: LogoCardContent[];
  title: string;
  titleColor?: Theme['palette']['text'];
  sectionBackground?: string;
}): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  return (
    <PersonCardsStyles
      themeText={titleColor}
      sectionBackground={sectionBackground}
    >
      <ContentBox
        className="logoCards-contentBox"
        sx={{ maxWidth: `${sizeOfGrid}px` }}
      >
        <Title component="h1" variant="h4" className="logoCards-Title">
          {title}
        </Title>
        <Divider height="3.3em" />
        <CardBox className="logoCards-cardBox">
          {content.map((card) => (
            <CardBoxContainer item key={card.id}>
              <Card elevation={0}>
                <Image src={card.src} />
              </Card>
            </CardBoxContainer>
          ))}
        </CardBox>
      </ContentBox>
    </PersonCardsStyles>
  );
};

export default logoCards;
