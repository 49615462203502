import React from 'react';
import FirstImage from 'images/ourTeam/adv_01.svg';
import SecondImage from 'images/ourTeam/adv_02.svg';
import ThirdImage from 'images/ourTeam/adv_03.svg';
import PersonCards from '../PersonCards';
import { Box, styled } from '@mui/system';
import { Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const content = [
  {
    id: 1,
    name: 'Claus Skaaning',
    src: FirstImage,
    linkedinUrl: 'https://www.linkedin.com/in/clausskaaning/',
    description: 'PhD, CEO DigiShares',
  },
  {
    id: 2,
    name: 'Nikolaj Juhl Hansen',
    src: SecondImage,
    linkedinUrl: 'https://www.linkedin.com/in/nikolaj-juhl-hansen-2830251/',
    description: 'Partner, Corporate/Commercial, DreistStorgaard, Denmark',
  },
];

export const GridSizedContainer = styled(Box)<{ sizeofgrid?: string }>`
  ${({ sizeofgrid }) => sizeofgrid && `max-width: ${sizeofgrid}px;`}
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const Advisors = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  return (
    <Box
      sx={{ backgroundColor: '#4E90C4' }}
      maxWidth="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Divider height="10.3em" />
        <PersonCards
          content={content}
          title="Our board of advisors"
          titleColor={'secondary'}
          sectionBackground={'#4E90C4'}
          mdColCount={4}
        />
        <Divider height="10.3em" />
      </GridSizedContainer>
    </Box>
  );
};

export default Advisors;
