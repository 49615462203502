import React from 'react';
import AIHouse from 'images/ourPartners/AIHouse.webp';
import Digishares from 'images/ourPartners/digi.webp';
import StartUP from 'images/ourPartners/StartUP.webp';
import Aryze from 'images/ourPartners/Aryze.webp';
import Penning from 'images/ourPartners/Penning.webp';
import Safe from 'images/ourPartners/Safe.webp';
import Microsoft from 'images/ourPartners/MC.webp';
import WalletConnect from 'images/ourPartners/Walletconnect.webp';
import Sumsub from 'images/ourPartners/sumsub.webp';
import CPHFintech from 'images/ourPartners/CPHFintech.webp';
import TechNordicAdvocates from 'images/ourPartners/Tech.webp';
import LogoCards from '../LogoCards';
import { Box } from '@mui/material';
import { Anchor, Divider } from 'utils';
import { GridSizedContainer } from '../AdvisorsSection/AdvisorsSection';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const content = [
  {
    id: 0,
    src: CPHFintech,
  },
  {
    id: 1,
    src: Microsoft,
  },
  {
    id: 2,
    src: WalletConnect,
  },
  {
    id: 3,
    src: Digishares,
  },
  {
    id: 4,
    src: Sumsub,
  },
  {
    id: 5,
    src: TechNordicAdvocates,
  },
  {
    id: 6,
    src: Aryze,
  },
  {
    id: 7,
    src: Safe,
  },
  {
    id: 8,
    src: Penning,
  },
  {
    id: 9,
    src: StartUP,
  },
  {
    id: 10,
    src: AIHouse,
  },
];

const OurTeamSection = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  return (
    <Box
      // maxWidth="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
      sx={{ backgroundColor: '#fff' }}
    >
      <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Divider height="70px" />
        <Anchor id="partners" />
        <LogoCards
          content={content}
          title="Our partners, collaborators and supporters"
          sectionBackground="#fff"
        />
        <Divider height="70px" />
      </GridSizedContainer>
    </Box>
  );
};

export default OurTeamSection;
