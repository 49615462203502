import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, Theme } from '@mui/system';
import { Range, StyledPropsFix } from 'types';
import { Grid, Link, Paper } from '@mui/material';
import LinkedInIco from 'images/ourTeam/linkedInIco.svg';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const Title = styled(Typography)`` as StyledPropsFix;
const ContentBox = styled(Container)``;

const PersonCardsStyles = styled(Box)<{
  themeText?: string;
  sectionBackground?: string;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ sectionBackground }) => sectionBackground};

  .personCards-contentBox {
    display: flex;
    flex-direction: column;
  }

  .personCards-Title {
    font-family: Roboto Bold;
    font-size: 2.1em;
    color: ${({ theme, themeText = 'dark' }) => theme.palette.text[themeText]};
    position: absolute;
    top: -80px;
    left: 0px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.6em;
      top: -60px;
    }
  }
`;

const CardGrid = styled(Grid)`` as StyledPropsFix;

const CardGridContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  gap: 1em;

  ${({ theme }) => theme.breakpoints.down('md')} {
    transform: scale(0.8);
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    justify-content: center;
  }
` as StyledPropsFix;

const Card = styled(Paper)`
  align-items: center;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  height: 26.8em;
  width: 21em;
  justify-content: center;
` as StyledPropsFix;

const ProfilePic = styled('img')`
  margin-bottom: 1em;
`;
const LinkedinIcon = styled(Link)<{ src: string }>`
  position: absolute;
  top: 0;
  right: 0;

  // reset button styles
  margin: 0;
  border-radius: unset;
  min-width: unset;
  padding: 0;
  z-index: 1;
  width: 40px;
  height: 40px;

  background-image: url(${({ src }) => src});
`;
const CardDescription = styled(Box)`
  font-family: Roboto Regular;
  font-size: 1.1em;
  text-align: center;
` as StyledPropsFix;

const CardName = styled(Box)`
  font-family: Roboto Bold;
  font-size: 1.1em;
` as StyledPropsFix;

interface PersonCardContent {
  id: number;
  name: string;
  src: string;
  linkedinUrl: string;
  description: string;
}

const PersonCards = ({
  content,
  title,
  titleColor,
  sectionBackground,
  mdColCount = 4,
}: {
  content: PersonCardContent[];
  title: string;
  titleColor?: Theme['palette']['text'];
  sectionBackground?: string;
  mdColCount?: Range<12>;
}): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <PersonCardsStyles
      themeText={titleColor}
      sectionBackground={sectionBackground}
    >
      <ContentBox
        className="personCards-contentBox"
        sx={{ maxWidth: `${sizeOfGrid}px` }}
      >
        <CardGrid container spacing={6} rowSpacing={3}>
          {content.map((card, indx) => {
            const sidePadding = '2.8em';
            const verticalPadding = '2.1em';

            return (
              <CardGridContainer
                item
                key={card.id}
                xs={12}
                sm={6}
                md={mdColCount}
              >
                <Card
                  elevation={0}
                  sx={{ padding: `${verticalPadding} ${sidePadding}` }}
                >
                  <LinkedinIcon
                    src={LinkedInIco}
                    target="_blank"
                    href={card.linkedinUrl}
                  />
                  {!indx && (
                    <Title
                      component="h1"
                      variant="h4"
                      className="personCards-Title"
                    >
                      {title}
                    </Title>
                  )}

                  <ProfilePic src={card.src} />

                  <Box textAlign="center" sx={{ flex: 1 }}>
                    <CardName>{card.name}</CardName>
                    <CardDescription>{card.description}</CardDescription>
                  </Box>
                </Card>
              </CardGridContainer>
            );
          })}
        </CardGrid>
      </ContentBox>
    </PersonCardsStyles>
  );
};

export default PersonCards;
