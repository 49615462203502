import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Anchor, Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const Title = styled(Typography)`` as StyledPropsFix;
const Text = styled(Typography)`` as StyledPropsFix;
const ContentBox = styled(Container)``;

const AboutSectionStyles = styled(Box)<{
  sectionBackground?: string;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ sectionBackground }) => sectionBackground};

  .aboutSection-contentBox {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #3f826d;
    padding: 0 12em;
    text-align: center;

    ${({ theme }) => theme.breakpoints.down('md')} {
      text-align: left;
      padding: 0 2em;
    }
  }
  .aboutSection-text {
    font-family: Roboto Regular;
    padding-bottom: 0.6em;
    font-size: 1.5em;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .text-v1 {
    font-size: 1.5em;
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.3em;
    }
  }
  .text-v2 {
    font-size: 1.1em;
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 0.9em;
    }
  }
  .text-middle {
    padding-top: 0.6em;
    padding-bottom: 0;
    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-top: initial;
    }
  }

  .aboutSection-Title {
    font-family: Poppins SemiBold;
    font-size: 3.1em;
    margin-bottom: 0.5em;
    color: ${({ theme }) => theme.palette.text.secondary};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 2.5em;
    }
  }
`;

const AboutSection = (): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <AboutSectionStyles sectionBackground={theme.palette.background.viridian}>
      <Box
        sx={{ backgroundColor: theme.palette.background.viridian }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Divider height="4.3em" />
        <ContentBox
          className="aboutSection-contentBox"
          sx={{ maxWidth: `${sizeOfGrid}px` }}
        >
          <Title className="aboutSection-Title" component="h1">
            Company
          </Title>
          <Anchor id="about-us" />
          <Text className="aboutSection-text text-v1" component="p">
            Coreestate’s mission is to revolutionize the rental property
            industry into affordable living for everyone.
          </Text>
          <Divider height="1.0em" />
          <Text className="aboutSection-text text-v2" component="p">
            We believe that everyone should be able to afford housing. Our aim
            is to disrupt the business model for rental properties with help of
            the blockchain and digital-assets.
          </Text>
          <Divider height="1.5em" />
          <Text className="aboutSection-text text-v2" component="div">
            The name Coreestate originated by combining the words
            <Text
              className="aboutSection-text text-v1 text-middle"
              component={isSmallScreen ? 'span' : 'p'}
            >
              {' '}
              Consumer, Rental and Estate,{' '}
            </Text>
            <Text
              className="aboutSection-text text-v2 text-middle"
              component={isSmallScreen ? 'span' : 'p'}
            >
              with the idea that rental properties should be owned by consumers
              themselves.
            </Text>
          </Text>
        </ContentBox>

        <Divider height="4.3em" />
      </Box>
    </AboutSectionStyles>
  );
};

export default AboutSection;
